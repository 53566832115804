import React, { useState, useEffect } from "react";
import "rapidoc";

function App() {
  const [specUrl, setSpecUrl] = useState("/openapi.json");

  useEffect(() => {
    const rapiDoc = document.querySelector("rapi-doc");
    if (rapiDoc) {
      rapiDoc.setAttribute("spec-url", specUrl);
    }
  }, [specUrl]);

  const handleSpecChange = (event) => {
    setSpecUrl(event.target.value);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#3b3b3b",
          padding: "5px",
          borderRadius: "4px",
          width: 220,
        }}
      >
        <label
          htmlFor="api-selector"
          style={{
            color: "#e0e0e0",
            marginRight: "10px",
            fontSize: "14px",
            marginTop: 2,
          }}
        >
          API endpoint type:
        </label>
        <select
          onChange={handleSpecChange}
          value={specUrl}
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: 1000,
            padding: "5px",
            backgroundColor: "#3b3b3b",
            color: "#e0e0e0",
            border: "1px solid #1db8c6",
            borderRadius: "4px",
          }}
        >
          <option value="/openapi.json">TF App</option>
          <option value="/aiopenapi.json">TF Agents</option>
        </select>
      </div>

      <rapi-doc
        render-style="read"
        style={{ height: "100vh", width: "100%" }}
        theme="dark"
        bg-color="#202021"
        text-color="#e0e0e0"
        primary-color="#1db8c6"
        nav-bg-color="#3b3b3b"
        nav-text-color="#e0e0e0"
        nav-hover-bg-color="#4a4a4a"
        nav-hover-text-color="#ffffff"
        nav-accent-color="#1db8c6"
        font-family="Arial, sans-serif"
        regular-font="Arial"
        mono-font="'Courier New', monospace"
        nav-logo="/logo.png"
        header-color="#3b3b3b"
        code-theme="dark"
        show-header={false}
      >
        <img
          alt="Logo"
          slot="logo"
          src="/logo.png"
          style={{
            width: "100px",
            height: "auto",
            marginRight: "10px",
          }}
        />
      </rapi-doc>
    </>
  );
}

export default App;
